:root {
  --primaryColor: #000687;
  --white: #fff;
  --headerHeight: 90px;
  --lightColor: rgba(0, 0, 0, 0.6);
}

.useLightColor {
  color: var(--lightColor);
}

body,
#root,
.MuiTypography-root {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #2a2a2a;
}

html,
#root,
body {
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Alexandria", sans-serif !important;
}

/* GENERAL */
.MuiButton-root {
  min-width: 100px;
}

svg {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

/*APP STYLES*/
.app-wrapper {
  min-height: 100%;
}

main {
  margin: 0;
  padding: 0;
  display: block;
  height: calc(100% - var(--headerHeight));
  box-sizing: content-box;
}


.auth-page main {
  height: 100vh;

}

#root .btn-primary,
#root .btn-rounded {
  border-radius: 50px;
}

#root .btn-white {
  background-color: var(--white);
  color: var(--primaryColor);
}

/* Auth */

.authContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authContainer .form_container {
  width: 50%;
  max-width: 700px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  /* height: 50%; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/* .authForm{
  width: 100%;
} */

.authContainer .right,
.authContainer .left {
  height: 100%;
  width: 50%;
}

.authContainer .right {
  border-radius: 16px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(234, 235, 239, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(234, 235, 239, 0.68);
}

div.round {
  background: rgba(0, 6, 135, 0.88);
  border-radius: 100%;
  height: 14rem;
  width: 14rem;
  position: relative;
}

div.round::after {
  content: '';
  display: block;
  height: 90%;
  position: absolute;
  bottom: -40%;
  left: -25%;
  margin-top: auto;
  width: 150%;
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
}

.authContainer .left {
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-color: #fff;
}

.authForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.reg-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 500px;
  text-align: center;
}

.reg-success .icon {
  height: 200px;
  width: 200px;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

/* Dashboard */


.footer-wrapper {
  width: 100%;
  background-color: var(--primaryColor);
}

.footer {
  width: 100%;
  color: #fff;
  padding: 3rem 0;
}

.footer a {
  color: #fff;
}

#root .inner {
  margin: 0 auto;
  max-width: 80%;
}

.footer .footer-menu-title {
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.footer .footer-menu-title.custom-title {
  margin: 20px 0;
}

.footer-menu-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.footer-wrapper .copyright {
  padding: 1rem 0;
}

.use-mask {
  position: relative;
  mask: url(../assets/mask.svg) no-repeat center;
  --webkit-mask: url(../assets/mask.svg) no-repeat center;
  display: none;
}

.info h2 {
  font-size: 3.5rem;
}

mark {
  background: rgba(145, 152, 243, 0.2);

  color: var(--primaryColor);
  border-radius: 15px;
  padding: 5px;
  color: #000;
}

.header-menu:before {
  display: none;
}


.header-menu {
  /* background: #f3f6fb; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.home .header-menu {
  background: transparent;
  box-shadow: none;
}


.header-menu * {
  color: #2a2a2a !important;
}

#root .btn-white {
  background-color: var(--primaryColor);
  color: var(--white) !important;
}

/**/

.header-menu {
  position: relative;
}

.header-menu div {
  z-index: 2;
}

.header-menu:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 6, 135, 0.8);
  /*     background: rgba(255,255,255,0.9); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 6, 135, 0.3);
}

.page-section.testimonial__section {
  /* margin: 2rem 0; */
  background: #e1e8f5;
  background: linear-gradient(115deg, #e1e8f5, #fbf9ef);
}

.page-section.chance {
  /* background: linear-gradient(115deg, #fbf9ef, #e1e8f5); */
  background: #fff;
  padding-bottom: 5rem;
}

.custom-shape-divider-top-1716214474 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1716214474 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 71px;
}

.custom-shape-divider-top-1716214474 .shape-fill {
  fill: #FFFFFF;
}

.footer-wrapper {
  background: #f3f6fb;
}

.footer-wrapper .footer * {
  color: #2a2a2a !important;
}

/*PROJECT DETAIL*/
.project-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-detail div:nth-child(2) {
  /* height: 100%; */
}

.authForm {
  width: 100%;
}

.MuiBackdrop-root {
/* From https://css.glass */
background: rgba(80, 91, 104, 0.1);
/* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05); */
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(3px);
}

/**/
@media screen and (max-width: 600px) {
  .home {
    flex-direction: column;
  }

  .footer-wrapper .footer {
    max-width: 90%;
  }
}



@media screen and (max-width: 768px) {
  #root .mobile-none {
    display: none;
  }

  .authContainer .form_container {
    max-width: 100%;
  }


  .authContainer .form_container {
    width: 90%;
    max-width: 90%;
  }

}

@media screen and (min-width: 768px) {}

@media screen and (max-width: 900px) {
  .container .image-div {
    display: none;
  }

  .authContainer .right {
    display: none;
  }

  .authContainer .left {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-shadow: none;
    gap: 4rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer-wrapper .footer {
    max-width: 100%;
  }

  .footer .inner .MuiGrid-item {}
}

@media screen and (max-width: 1210px) {
  .process_elements {
    column-gap: 2.5rem;
    row-gap: 1rem;
    max-width: 100%;
  }

  .authContainer .right {
    display: none;
  }

  .process-item {
    scale: 0.8;
  }
}

@media screen and (min-width: 1210px) {
  .hero-section {
    height: 70vh;
  }

  .process_elements {
    column-gap: 5rem;
    row-gap: rem;
    max-width: 90%;
  }

  .process-item {
    scale: 0.9;
  }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 768px) {
  .info h2 {
    font-size: 3rem;
  }

  .hero-section-2 {
    height: 80vh;
  }

  .hero-section-2 .info {
    max-width: 100%;
    padding: 6rem 3rem 3rem;
  }
}

@media screen and (max-width: 380px) {
  .info h2 {
    font-size: 2.4rem;
  }

  .hero-section-2 {
    height: 90vh;
  }

  .hero-section-2 .info {
    max-width: 100%;
    padding: 5rem 2rem 3rem;
  }
}