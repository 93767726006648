:root {
    --shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    --primaryColor: #000687;
    --secColor: #878000;
    --white: #fff;
    --lightColor: rgba(0, 0, 0, 0.6);
    --gradient: linear-gradient(115deg, #9197F3, #F9F7C7, #C7CAF9);
}


html,
#root,
body {
    background: #fafafa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Alexandria", sans-serif !important;
}

.dashboard header {
    padding-left: 4px;
    padding-right: 0;
    box-shadow: var(--shadow);
    background: transparent;
}

.dashboard .logo {
    height: 90px;
    /* justify-content: center; */
}

.dashboard .logo * {
    font-weight: 400;
    font-family: "Alexandria", sans-serif !important;
}

.logo a {
    display: flex;
    align-items: center;
    gap: 0.1rem;
    justify-content: center;
}

.logo .logo-icon {
    height: 55px;
    width: 55px;
    display: inline-flex;
}

.dashboard .dashboard-nav {
    background: linear-gradient(115deg, #9197F3, #F9F7C7);
    background: linear-gradient(115deg, #9197F3, #F9F7C7, #C7CAF9);
    background: #fff;
}

#root .dashboard-nav .MuiPaper-root {
    background: transparent;
    background: rgba(255, 255, 255, 0.93);
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20.1px);
    -webkit-backdrop-filter: blur(20.1px);
    border-right: 1px solid #f2f2f2 !important;
}

#root .dashboard-main {
    box-sizing: border-box;
    padding: 2rem;
    max-width: 100vw;
    padding-top: 122px;
}

.home {
    height: 100%;
}

.dashboard .dashboard-menu-list .Mui-selected,
#root .useGradient {
    background: linear-gradient(115deg, #9197F3, #F9F7C7, #C7CAF9);
    position: relative;
}

.dashboard .dashboard-menu-list .Mui-selected {
    border-right: 3px solid var(--primaryColor);
}

.dashboard .dashboard-menu-list .Mui-selected>*,
#root .useGradient>* {
    z-index: 1;
}

.dashboard .dashboard-menu-list .Mui-selected::before,
#root .useGradient::before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    background: rgba(255, 255, 255, 0.83);
    /* box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(20.1px);
    -webkit-backdrop-filter: blur(20.1px);
}

.topbar {
    box-sizing: border-box;
    margin: 0;
    background-color: #fff;
}

.home .aside {
    overflow-y: auto;
    flex: 1;
}

#root .dashboard-menu-item {
    margin: 1.5rem auto;
}

#root .dashboard-menu-btn {
    padding: 1rem 0;
}

#root .dashboard-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#root .dashboard-menu-list {
    margin-top: 10vh;
}

.sidebar-web .MuiPaper-root {
    display: flex;
    height: 100vh;
}

#root .sidebar-web .MuiPaper-root .dashboard-menu-footer {
    margin-top: auto;
}

/**/
.app-content {
    width: 65%;
    border-radius: 1rem;
    box-shadow: none;
}

.project-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.84);
    backdrop-filter: blur(10.1px);
    -webkit-backdrop-filter: blur(10.1px);
}

.project-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-list {
    width: 100%;
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    grid-row-gap: 2rem;
}

.project-item {
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
}

.home .stat-bar {
    padding: 1rem 2rem;
    margin-top: 0;
}

.home {
    display: flex;
    justify-content: space-between;
}

.card-item {
    padding: 1rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.bookings,
.projects-card,
.home .stat-bar {
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #fff;
}

.card-item,
.booking-list,
.invoice-list {
    display: flex;
    flex-direction: column;
}

.card-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.booking-item,
.project-item-card {
    background-color: #fff;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.project-item-card {
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.project-item-card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 1px 1px;
    transform: scale(1.001);
    background-color: #fafafa;
}

#root .stats {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
    min-height: 65px;
    max-width: 100%;
}

.stat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--shadow);
    padding: 2rem;
    gap: 10px;
    border-radius: 1rem;
    background-color: var(--white);
    height: 100%;
}

.stat-container {
    gap: 1rem;
}

.stat-container .stat {
    flex: 1;
}

.stat .text {
    color: var(--lightColor);
    font-weight: 400;
}

.stats .dot {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-item {
    text-align: left;
}

.project-list,
.tab-content {
    background: transparent;
}

.tab-bar button:first-of-type {
    text-align: left;
}

#root .greeting-text {
    color: var(--lightColor);
    font-weight: 300;
}

.project-detail .MuiTabPanel-root {
    min-height: 50vh;
}

.project-detail .Mui-selected,
.project-detail .MuiTabPanel-root {
    background-color: #fff;
}

.project-detail .Mui-selected {
    color: var(--secColor);
}

.project-detail .MuiTabs-indicator {
    background: #f2f2f2;
}

.use-table {
    width: 100%;
    margin: 1rem 0 1rem 0;
    table-layout: fixed;
}

.use-table th:first-of-type,
.use-table tr:first-of-type,
.use-table td:first-of-type {
    text-align: left;
}

.use-table th,
.use-table tr,
.use-table td {
    text-align: center;
}

.use-table th {
    font-weight: 500;
    color: var(--lightColor);
}

.use-table td {
    font-weight: 500;
}

.use-table th,
.use-table td {
    padding: 1rem 0;
    width: 33%;
}

.use-table td {
    font-weight: 400
}

.billing-table-container {
    padding-bottom: 1rem;
}

.use-table tbody tr {
    background-color: rgba(255, 255, 255, 0.1);
}

.use-table tbody tr td {
    transition: all ease-in-out 0.2s;
}

.use-table .MuiInputBase-input,
.use-table .MuiInputBase-input {
    border-radius: 5px;
}

.billing-table-container {
    overflow-x: auto;
}

.date-picker-wrapper .MuiInputBase-root {
    height: 35px;
}

.booking-table {
    box-shadow: var(--shadow);
    background-color: var(--white);
    border-radius: 1rem;
}

.booking-form label {
    font-weight: 600;
    color: var(--lightColor);
    margin-bottom: 1rem;
}

.search-user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    width: 100%;
    box-shadow: var(--shadow);
    min-height: 300px;
    /* max-height: 50%; */
    overflow: auto;
}

.card-box {
    box-shadow: var(--shadow);
    padding: 1rem;
    border-radius: 1rem;
    background-color: #fff;
}

.booking-summary span {
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--lightColor);
}

.booking-summary p {
    margin: 0;
    font-weight: 500;
    margin-top: 10px;
}

.dashboard-main .tab-content {
    padding-top: 0;
}

@media screen and (max-width: 600px) {
    .billing-table-wrapper {
        /* scale: 0.98; */
    }

    .use-table {
        table-layout: auto;
    }

    .use-table th,
    .use-table td {
        font-size: 14px;
        /* display: table-cell; */
    }

    .billing-table-wrapper {
        overflow-x: auto;
    }

    .stat-container {
        margin-top: 1rem;
    }

    .stat-container .stat,
    .stat-bar .stat {
        padding: 10px;
        /* word-break: break-all; */
        word-wrap: break-word;
    }

    .stat-bar .stat {
        /* max-width: 32%; */
        flex: 1;
    }

    #root .stats {
        gap: 2px;
    }

    #root .dot {
        /* display: none; */
    }

    .app-content,
    .project-list {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        grid-row-gap: 2rem;
    }

    #root .app-content {
        border-radius: 0;
        box-shadow: none;
        background: transparent;
    }

    #root .dashboard-main {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 6rem;
        padding-top: 90px;
        min-height: 100vh;
    }

    #root .MuiBottomNavigationAction-root svg {
        font-size: 1.3rem;
    }

    #root .appbar .logo {
        padding-left: 0;
    }

    .dashboard header {
        padding: 0;
        height: 70px;
    }

    #root .topbar {
        min-height: 40px;
        padding: 0.5rem;
        padding-right: 1rem;
    }

    .logo .logo-icon {
        height: 40px;
        width: 40px;
    }

    #root .topbar .MuiAvatar-root {
        height: 35px;
        width: 35px;
    }

    .project-list-wrapper,
    .project-item,
    .bookings,
    .projects-card,
    .home .stat-bar,
    .booking-item {
        border-radius: 0.5rem;
    }

    .home .stat-bar {
        margin-bottom: 0;
    }

    .authContainer {
        box-sizing: border-box !important;
    }

    .auth-page main,
    #root .authContainer,
    #root .auth-page {
        height: 92vh;
    }

    .authContainer .left {
        gap: 3rem;
        justify-content: space-between;
        /* overflow-y: hidden; */
    }

    .search-user {
        max-height: 65vh;
    }
}

@media screen and (min-width: 600px) {


    #root,
    .dashboard {
        height: 100vh;
    }

    #root .dashboard-main {
        height: 100% !important;
        overflow-y: scroll;
    }

    .dashboard-main {
        overflow-y: auto;
    }

    #root .dashboard-nav,
    #root .dashboard-nav .MuiPaper-root {
        /* background: #f5f5f5; */
        border: none;
    }

}

@media screen and (max-width: 900px) {
    .home {
        flex-direction: column;
        overflow-y: auto;
        height: auto;
    }

    .app-content {
        padding: 0;
    }

    .app-content,
    .aside {
        width: 100%;
    }

    .home .aside {
        flex: initial;
    }
}

@media screen and (max-width:1280px) {
    .stat {
        padding: 1rem;
    }
}

@media screen and (min-width: 900px) {

    #root .dashboard-main {
        height: 100% !important;
        overflow-y: auto;
        /* padding-bottom: 0; */
    }
}

.portfolio-form-container {
    margin: 0 !important;
    width: 100%;
}

.portfolio-form-container .MuiGrid-root {
    padding: 1rem 0.5rem;
}

.form-field {
    margin-bottom: 1rem;
}

.image-upload {
    min-height: 200px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.image-upload p {
    display: flex;
    align-self: center;
    justify-self: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    height: auto;
}

.image-upload-modal .image-upload-button {
    background-color: #444;
    border-radius: 50%;
    /* From https://css.glass */
    background: rgba(242, 242, 242, 0.8);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(242, 242, 242, 0.3);
}

.image-upload-modal {
    border-radius: 1rem;
    min-height: 500px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* From https://css.glass */
    background: rgba(242, 242, 242, 0.9);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(242, 242, 242, 0.3);
}

.portfolio-item {
    background-color: #fff;
    margin: 1rem 0;
    text-transform: capitalize;
    cursor: pointer !important;
    border-radius: 1rem;
}

.drag-handle {
    cursor: grab;
}

.dashboard-main>.dashboard-main>.MuiGrid-root:first-of-type {
    overflow-y: auto;
}

.home>.MuiGrid-root {
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

}