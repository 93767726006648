:root {
    --chatBg: #f2f2f2;
}

#root .chat-page .dashboard-main {
    padding: 0;
    padding-bottom: 0;
    padding-top: 90px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.chat .MuiTabPanel-root {
    height: 10%;
}

.chat-wrapper {
    display: flex;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.chat-list-header {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
    border-bottom: solid 1px #fff1e6;
    /* background-color: #fafafa; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.chat-page .chat-wrapper {
    box-shadow: none;
    border-radius: 0;
}

.use-full-width {
    flex-basis: 100% !important;
    max-width: 100% !important;
}

.cs-chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
}

.cs-chat-container>* {
    flex: 1 1 auto;
}

.cs-chat-container .cs-message-list {
    flex: 1 1 auto;
    overflow-y: auto;
}

.cs-chat-container .cs-message-input {
    flex: 0 0 auto;
    padding: 1rem;
    padding-right: 0;
}

.message-input-box {
    border-top: solid 1px #d1dbe3;
}

.message-input-box>button {
    margin-right: 1rem;
}

#root .chat-tab {
    padding: 0;
    display: flex;
    height: 100%;
}

#root .cs-conversation-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
}

.cs-message-input__content-editor-wrapper {
    margin-right: 0 !important;
}

.cs-conversation-header,
.cs-message-input__content-editor {
    background: #fff;
}

.cs-message--outgoing .cs-message__content {
    background: #f1f1f1;
}

.cs-message-input__content-editor-container,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
    background: #f1f1f1;
}

.cs-message--outgoing .cs-message__content,
.cs-message--incoming .cs-message__content {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.cs-main-container,
.cs-chat-container,
.chat-tab,
.cs-message-list {
    background-color: #F6F4F1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background:
        radial-gradient(circle farthest-side at 0% 50%, #f6f4f1 23.5%, #0000 0)63px 90px,
        radial-gradient(circle farthest-side at 0% 50%, #f2efef 24%, #0000 0)57px 90px,
        linear-gradient(#f6f4f1 14%, #0000 0, #0000 85%, #f6f4f1 0)0 0,
        linear-gradient(150deg, #f6f4f1 24%, #f2efef 0, #f2efef 26%, #0000 0, #0000 74%, #f2efef 0, #f2efef 76%, #f6f4f1 0)0 0,
        linear-gradient(30deg, #f6f4f1 24%, #f2efef 0, #f2efef 26%, #0000 0, #0000 74%, #f2efef 0, #f2efef 76%, #f6f4f1 0)0 0,
        linear-gradient(90deg, #f2efef 2%, #f6f4f1 0, #f6f4f1 98%, #f2efef 0%)0 0 #f6f4f1;
    background-size: 120px 180px;
    background-color: #f6f4f1;

}

.cs-main-container,
.cs-message-list,
.cs-main-container .cs-chat-container,
.cs-conversation-header {

    border: none;
    padding: 0;
}

.cs-conversation-header__content h6 {
    font-size: 1rem;
    margin: 10px 0;
}

#root .cs-conversation-header {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.cs-conversation-header__avatar {
    display: none;
}

.hide-footer .fixed-footer {
    display: none;
}

.text-input .cs-message-input__content-editor div * {
    white-space: initial !important;
    background: #f2f2f2 !important;
    color: #2a2a2a !important;
}

.cs-button {
    min-height: 40px;
    width: 40px;
}

.message-input-box {
    width: 100%;
}

.message-input-box .text-input {
    flex: 1;
    border: none;
}

.file-message {
    border-radius: 1rem;
    background: #fff;
    margin-top: 0.3rem !important;
    width: 250px;
    border-bottom-right-radius: 0;
    /* height: 150px; */
}

.file-message .cs-message__content {
    padding: 0;
}

.file-message .cs-message__content-wrapper {
    width: 100%;
}

.ps__thumb-y {
    background-color: var(--primaryColor);
}

@media screen and (max-width: 768px) {

    #root .cs-conversation-header,
    .cs-main-container,
    .cs-message--outgoing .cs-message__content,
    .cs-message--incoming .cs-message__content {
        box-shadow: none;
    }

    #root .cs-main-container {
        border-radius: 0;
    }

    .cs-main-container>.cs-sidebar.cs-sidebar--left {
        flex-basis: 100%;
        max-width: 100%;
        ;
    }

    #root .chat-page .dashboard-main {
        padding-top: 95px !important;
    }
}

@media screen and (max-width: 450px) {
    #root .chat-page .dashboard-main {
        padding-top: 64px !important;
    }
}